import { Keyring } from '@polkadot/keyring';
import { handleSignedTransactionFromAlice, transactionErrorHandler } from './handler';

export const airdrop = async (api, accountId) => {
  try {
    if (api === null) return;
    const keyring = new Keyring({ type: 'sr25519', ss58Format: 2 });
    const alice = await keyring.addFromUri('//Alice');
    const amount = 500000000000000;
    const transaction = api.tx.balances.transfer(accountId, amount);
    await handleSignedTransactionFromAlice(transaction, alice);
  } catch (error) {
    console.error(error);
    transactionErrorHandler(error);
  }
};
