export const convertBalance = balanceString => {
  const brokenBalance = balanceString.split(',');
  if (brokenBalance.length > 3) {
    brokenBalance.pop();
    brokenBalance.pop();
    brokenBalance.pop();
  }
  var intBalance = 0;
  for (let i = 0; i < brokenBalance.length; i++) {
    const curr = brokenBalance[i];
    const mul = 10 ** curr.length;
    intBalance = intBalance * mul + parseInt(curr);
  }
  intBalance = intBalance * 1.0;
  return intBalance;
};
