import React from 'react';
import { FaDiscord, FaInstagram, FaTwitter, FaTelegram, FaLinkedin, FaFacebookF, FaYoutube } from 'react-icons/fa';
import background from '../../assets/bg.png';
import footer from '../../assets/footer.png';

const Footer = () => {
  return (
    <div className="relative bg-black-variant">
      <footer className="max-w-[270px] xs:max-w-sm sm:max-w-xl md:max-w-4xl lg:max-w-5xl xl:max-w-[92rem] mx-auto flex items-center justify-center h-[700px] ">
        <div
          style={{
            background: 'linear-gradient(180deg, rgba(0, 148, 255, 0) 0%, #0094FF 50%, rgba(0, 148, 255, 0) 100%)',
            filter: 'blur(122.5px)',
          }}
          className="absolute w-full h-[96px] left-0 top-[120px] opacity-50"
        ></div>
        <div
          style={{
            background: `url(${background})`,
          }}
          className="absolute w-full h-[544px] left-0 top-0"
        ></div>
        <div className="absolute top-0 flex flex-col xs:w-sm sm:w-[500px] md:w-[760px] lg:w-[1000px] xl:w-[1400px] p-4 gap-[14rem] pt-[120px]">
          <div className="md:p-[2rem] lg:p-0 grid grid-cols-2 gap-8 lg:gap-0 md:grid-cols-7 place-items-center h-[96px] ">
            <div className="flex flex-col items-center px-2 h-[96px]">
              <p className="font-secondary text-[48px] font-[400] m-0 leading-[64px] text-center text-secondary  ">
                5000+
              </p>
              <p className=" text-center h-[32px] font-inter text-secondary ">MetaHomes</p>
            </div>
            <div className="hidden md:block w-[96px] h-0 opacity-40 border border-secondary rotate-90 "></div>
            <div className="flex flex-col items-center  h-[96px]">
              <p className="font-secondary text-[48px] font-[400] m-0 leading-[64px] text-center text-secondary  ">
                300+
              </p>
              <p className=" text-center h-[32px] font-inter text-secondary ">Events Held</p>
            </div>
            <div className="hidden md:block w-[96px] h-0 opacity-40 border border-secondary rotate-90 "></div>
            <div className="flex flex-col items-center  h-[96px]">
              <p className="font-secondary text-[48px] font-[400] m-0 leading-[64px] text-center text-secondary  ">
                17
              </p>
              <p className=" text-center h-[32px] font-inter text-secondary ">Collections</p>
            </div>
            <div className="hidden md:block w-[96px] h-0 opacity-40 border border-secondary rotate-90 "></div>
            <div className="flex flex-col items-center  h-[96px]">
              <p className="font-secondary text-[48px] font-[400] m-0 leading-[64px] text-center text-secondary  ">
                200+
              </p>
              <p className=" text-center h-[32px] font-inter text-secondary ">Creators</p>
            </div>
          </div>
          <div className="flex flex-col md:flex-row gap-[3rem] items-center justify-between h-[80px]">
            <div className="w-[230px] h-[67.61px] left-0">
              <img src={footer} alt="" className="" />
            </div>
            <div className="flex flex-col items-start p-0 gap-[0px] w-[250px] md:w-[408px] h-[80px]">
              <p className="w-[303px] h-[36px] font-secondary text-[#4af8ff] font-[400] text-[24px] leading-[150%] tracking-[1px] uppercase  ">
                Join our community
              </p>
              <div className="w-[250px] md:w-[408px] h-[24px] text-[#e6fff7] flex items-start justify-between">
                <FaDiscord size={20} />
                <FaInstagram size={20} />
                <FaTwitter size={20} />
                <FaTelegram size={20} />
                <FaFacebookF size={20} />
                <FaLinkedin size={20} />
                <FaYoutube size={20} />
              </div>
            </div>
          </div>
        </div>
        <div className="font-sans text-center absolute bottom-[20px] w-[300px] h-[24px] font-[400] text-[16px] leading-[24px] text-[#efefef]">
          &#169; 2022 MetaHome, Powered by Social.li
        </div>
      </footer>
    </div>
  );
};

export default Footer;
