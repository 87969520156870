import React from 'react';
import { useState } from 'react';
import { FaArrowRight } from 'react-icons/fa';
import { initiateUser } from '../actions';
import { getUserAccountDetails } from '../service/storageReader';
import { airdrop } from '../service/Transactions/metahomeDexExtrinsics';
import { convertBalance } from '../service/Transactions/utils';

const ClaimToken = ({ api, wallet, setStep }) => {
  const [claimed, setClaimed] = useState(false);
  const handleclick = async () => {
    const tokens = await airdrop(api, wallet.selectedAccount.address);
    setTimeout(async () => {
      const tokenDetails = await getUserBalanceFromBlockchain(wallet.selectedAccount.address);
      initiateUser(tokenDetails);
    }, 6000);
    setClaimed(true);
  };

  const getUserBalanceFromBlockchain = async accountId => {
    const userAccountDetails = await getUserAccountDetails(api, accountId);
    const balanceOfUser = convertBalance(userAccountDetails.data.free);
    const tokenDetails = {
      balance: balanceOfUser,
    };
    return tokenDetails;
  };

  return (
    <div className="relative">
      <h1 className="text-2xl font-secondary font-[400] text-gray-700 text-center">
        {claimed ? `You're all set` : 'Would you like some free Socialli tokens?'}
      </h1>
      <div className="flex items-center justify-center">
        {!claimed && (
          <button
            onClick={handleclick}
            type="button"
            style={{
              background: 'linear-gradient(265.43deg, #721EB5 -33.06%, #1E6CB5 134%)',
            }}
            className="text-white text-center w-[150px] font-[400] tracking-wide bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300  rounded-md text-md px-5 py-2.5 mr-2 mb-2 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700"
          >
            Claim Tokens
          </button>
        )}
        <button
          onClick={() => setStep(4)}
          style={{
            background: 'linear-gradient(265.43deg, #721EB5 -33.06%, #1E6CB5 134%)',
          }}
          className="text-white text-center w-[150px] font-[400] tracking-wide bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300  rounded-md text-md px-5 py-2.5 mr-2 mb-2 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700"
          type="button"
        >
          {/* <FaArrowRight size={24} />
           */}
          Proceed
        </button>
      </div>
    </div>
  );
};

export default ClaimToken;
