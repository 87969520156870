import React from 'react';
import { useNavigate } from 'react-router-dom';
import { avatarApiUrl } from '../Views/Avatar';

const ConfirmAvatar = ({ avatarUrl }) => {
  const avatar = avatarApiUrl(avatarUrl);
  const navigate = useNavigate();
  return (
    <div className="flex items-center relative justify-center gap-[15px]">
      <div>
        <img src={avatar} alt="" className={`w-80 h-80 rounded-md shadow-md`} />
      </div>
      <div className="self-start  flex flex-col items-center flex-1">
        <div>
          <h2 className="font-main font-bold text-xl">Create your Avatar</h2>
          <p className="w-full text-lg">We have generated a default avatar for you.</p>
          <p className="w-full text-lg">Proceed with the same avatar?</p>
        </div>
        <div className="absolute bottom-[5px] right-[15px] ">
          <button
            onClick={() => navigate('/avatar')}
            type="button"
            className="text-white bg-red-600 tracking-wide font-semibold hover:bg-red-700 focus:outline-none focus:ring-4 focus:ring-red-500 rounded-lg text-sm px-5 py-2.5 mr-2 mb-2"
          >
            Create New
          </button>
          <button
            onClick={() => navigate('/metahome')}
            type="button"
            className="text-white tracking-wide font-semibold bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300  rounded-lg text-sm px-5 py-2.5 mr-2 mb-2"
          >
            Play
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmAvatar;
