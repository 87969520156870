import { WALLET, USER, API } from '../types';

export const connectWallet = wallet => {
  return {
    type: WALLET,
    payload: wallet,
  };
};

export const initiateUser = user => {
  return {
    type: USER,
    payload: user,
  };
};

export const initiateApi = api => {
  return {
    type: API,
    payload: api,
  };
};
