import React from 'react';
import aboutImage from '../../assets/about.png';
import background from '../../assets/bg.png';

const About = () => {
  return (
    <div className="bg-black-variant w-[100%] h-screen relative">
      <div style={{ background: `url(${background})` }} className="absolute w-full z-10 h-full left-0 top-0"></div>
      <div className="max-w-[300px] p-2 xs:max-w-sm sm:max-w-2xl md:max-w-4xl lg:max-w-5xl xl:max-w-[92rem] mx-auto relative">
        <div className="w-full h-screen flex items-center gap-[50px] lg:gap-[100px] justify-center">
          <div className="hidden md:block left-[80px] top-[183px] z-10">
            <img src={aboutImage} alt="3D House Image" className="relative" />
            <div
              style={{ filter: 'blur(150px)' }}
              className="w-[325px] z-[-10] h-[325px] absolute xl:left-[20%] top-[35%] bg-[#0094ff] opacity-80"
            ></div>
          </div>
          <div className="flex flex-col items-start gap-[36px] xs:w-[416px] h-[464px]">
            <p className="h-[36px] font-main font-semibold text-[24px] leading-[150%] tracking-[1px] text-[#4af8ff] uppercase">
              About Metahome
            </p>
            <div className="flex flex-col items-start gap-[220px] xs:gap-[120px] xs:w-[416px] h-[392px] z-10">
              <div>
                <p
                  className="font-main font-[400] xs:w-[416px] h-[144px] text-[16px] leading-[150%] tracking-[1px] text-secondary"
                  style={{ textShadow: '0px 0px 2px rgba(1, 255, 176, 0.3)' }}
                >
                  Through MetaHomes, we’re building an entirely new world for the next level of connection, economy,
                  entertainment, and a space on the internet to live. Everything they will create and build on Social.li
                  platform will be interoperable and functional in other virtual spaces constructed on different
                  platforms and blockchains. We’re making a new internet feel more like home and is decentralized and
                  equally rewarding to creators.
                </p>
              </div>
              <div className="w-[219px] h-[42px] flex flex-col items-start z-10">
                <button className="gap-[24px] bg-[#0E1815] w-[219px] h-[42px] p-0 ">
                  <div
                    className="w-[219px] h-[42px] border border-[#e6fff7] btn"
                    style={{
                      boxShadow: '0px 0px 8px rgba(255, 255, 255, 0.4)',
                    }}
                  >
                    <div className="flex flex-col  items-start py-[8px] px-[24px] w-[230px] h-[52px] ">
                      <p
                        style={{
                          textShadow: '0px 0px 2px rgba(1, 255, 176, 0.3)',
                        }}
                        className="text-[16px] w-[181px] h-[24px] text-center tracking-[1px] text-[#e6fff7] font-main font-semibold leading-[150%] uppercase"
                      >
                        View whitepaper
                      </p>
                      <div className="w-[161px] relative h-0 z-[-10]">
                        <div className="box-border absolute w-[10px] h-[10px] left-[-28px] top-[-52px] border-[4px] border-[#e6fff7]"></div>
                        <div className="box-border absolute w-[10px] h-[10px] right-[-36px] top-[-52px] border-[4px] border-[#e6fff7]"></div>
                        <div className="box-border absolute w-[10px] h-[10px] left-[-28px] bottom-[3.5px] border-[4px] border-[#e6fff7]"></div>
                        <div className="box-border absolute w-[10px] h-[10px] right-[-36px] top-[-13.5px] border-[4px] border-[#e6fff7]"></div>
                      </div>
                    </div>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
