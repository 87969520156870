import { axiosInstance } from './axios.default';

export const createWorldHandler = async (accountId, name, avatarUrl) => {
  console.log('Create world handler called with - ', accountId, name, avatarUrl);
  let response = {
    status: false,
  };
  try {
    const result = await axiosInstance.post('/worlds', {
      accountId,
      name,
      avatarUrl,
    });
    if (result.status === 200 || result.status === 201) {
      response.status = true;
      response.data = result.data;
    }
    return response;
  } catch (error) {
    console.error(error);
    return response;
  }
};

export const updateWorldHandler = async (accountId, worldName, avatarUrl) => {
  console.log('Update world handler called with - ', accountId, worldName, avatarUrl);
  let response = {
    status: false,
  };
  try {
    const result = await axiosInstance.put(`/worlds/updateWorld/${accountId}/${worldName}`, {
      avatarUrl,
    });
    if (result.status === 200 || result.status === 201) {
      response.status = true;
      response.data = result.data;
    }
    return response;
  } catch (error) {
    console.error(error);
    return response;
  }
};

export const getWorldByUserAndWorld = async (accountId, worldName) => {
  let response = {
    status: false,
  };
  try {
    const result = await axiosInstance.get(`/worlds/getWorldByUserAndWorld/${accountId}/${worldName}`, {
      accountId,
      worldName,
    });
    if (result.status === 200 || result.status === 201) {
      response.status = true;
      response.data = result.data;
    }

    return response;
  } catch (error) {
    console.error(error);
    return response;
  }
};

export const getAllWorldsByUser = async accountId => {
  let response = {
    status: false,
  };
  try {
    const result = await axiosInstance.get(`/worlds/getAllWorldsByUser/${accountId}`, {
      accountId,
    });
    if (result.status === 200 || result.status === 201) {
      response.status = true;
      response.data = result.data;
    }

    return response;
  } catch (error) {
    console.error(error);
    return response;
  }
};

export const updateWorld = async (accountId, worldName, avatarUrl) => {
  console.log('updating world', accountId, worldName, avatarUrl);
  let response = {
    status: false,
  };
  try {
    if (!worldName) {
      return response;
    }
    const result = await axiosInstance.put(`/worlds/updateWorld/${accountId}/${worldName}`, {
      avatarUrl,
    });
    console.log('result = ', result);
    if (result.status === 200 || result.status === 201) {
      response.status = true;
      response.data = result.data;
    }

    return response;
  } catch (error) {
    console.error(error);
    return response;
  }
};
