import React, { useEffect, useState } from 'react';
import { FaRegUserCircle, FaEdit, FaBars, FaTimes } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import logo from '../../assets/logo.png';
import { avatarApiUrl } from '../../Views/Avatar';
import { notify } from '../../App';
import { getWorldByUserAndWorld } from '../../controller/world.controller';

const Navbar = ({ handleConnectWallet, balance, handleLogout, handleAirdrop, handleModal }) => {
  const [avatar, setAvatar] = useState('');
  const wallet = useSelector(state => state.walletReducer);
  const userDetails = useSelector(state => state.userReducer);
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (userDetails && userDetails.avatarUrl) {
      console.log(userDetails.avatarUrl);
      const avatar2dUrl = avatarApiUrl(userDetails.avatarUrl);
      setAvatar(avatar2dUrl);
    } else {
      setAvatar('');
    }
  }, [userDetails, wallet]);

  return (
    <>
      <nav className="max-w-[300px] xs:max-w-[28rem] sm:max-w-[38rem] md:max-w-4xl lg:max-w-7xl xl:max-w-[100rem] mx-auto flex items-center justify-between h-[80px] lg:px-[4rem] pt-3">
        <div>
          <img src={logo} alt="" className="cursor-pointer max-w-[200px] h-[35.61px]" />
        </div>
        <div className="z-[10] px-8 hidden md:block">
          <ul className="hidden lg:flex uppercase text-primary font-main font-[500] items-center justify-center gap-[3rem]">
            <li
              onClick={handleModal}
              style={{ textShadow: '0px 0px 2px rgba(1, 255, 176, 0.3)' }}
              className="font-[500] cursor-pointer btn text-[18px] leading-[100%] text-center tracking-[1px] hover:text-gray-300 "
            >
              Claim Tokens
            </li>
            <li
              style={{ textShadow: '0px 0px 2px rgba(1, 255, 176, 0.3)' }}
              className="font-[500] cursor-pointer text-[18px] leading-[100%] text-center tracking-[1px] hover:text-gray-300 "
            >
              Marketplace
            </li>
            <li
              style={{ textShadow: '0px 0px 2px rgba(1, 255, 176, 0.3)' }}
              className="font-[500] cursor-pointer text-[18px] leading-[100%] text-center tracking-[1px] hover:text-gray-300 "
            >
              Builder
            </li>
            {/* <li
              style={{ textShadow: '0px 0px 2px rgba(1, 255, 176, 0.3)' }}
              className="font-[500] group relative flex btn items-center justify-center cursor-pointer text-[18px] leading-[100%] text-center tracking-[1px] hover:text-gray-300 "
            >
              {avatar !== '' ? (
                <div className="border-2 border-white rounded-full mr-2">
                  <img src={avatar} alt="" className={`w-12 rounded-full shadow-md`} />
                </div>
              ) : (
                <FaRegUserCircle size={34} className="mr-2 w-12 relative" />
              )}

              {avatar === '' ? (
                <div className="group-hover:block dropdown-menu absolute top-[100%] hidden h-auto">
                  <ul className="top-0 w-48 bg-white rounded-md shadow-xl mt-3 py-1 px-0">
                    {!userDetails?.email && wallet && (
                      <li
                        onClick={() => {
                          if (wallet) {
                            return navigate('/register');
                          } else {
                            notify('error', 'Please connect your wallet first!');
                          }
                        }}
                      >
                        <a className="block px-4 truncate py-2 text-sm text-black-variant font-semibold hover:bg-gray-500 hover:text-white cursor-pointer">
                          {' '}
                          Register User
                        </a>
                      </li>
                    )}
                    <li
                      onClick={() => {
                        if (wallet) {
                          return navigate('/avatar');
                        } else {
                          notify('error', 'Please connect your wallet first!');
                        }
                      }}
                    >
                      <a className="block px-4 truncate py-2 text-sm text-black-variant font-semibold hover:bg-gray-500 hover:text-white cursor-pointer">
                        {' '}
                        Create Avatar
                      </a>
                    </li>
                  </ul>
                </div>
              ) : (
                <div className="group-hover:block dropdown-menu rounded-sm bg-[#0e1815] px-4 py-0 mt-1.5 absolute top-[100%] hidden h-auto">
                  <div className="flex flex-col items-center justify-center">
                    <div className="top-0 w-[180px] mt-3 py-1 relative border border-[#e6fff7]">
                      <img src={avatar} alt="" className="rounded-md bg-contain" />
                    </div>
                    <span onClick={() => navigate('/avatar')} className="absolute top-[10%] right-[12%] text-[#e6fff7]">
                      <FaEdit />
                    </span>
                    <div>
                      <h2 className="truncate py-2 text-[14px] text-white font-[400] font-secondary ">
                        {userDetails && userDetails.name}
                      </h2>
                    </div>
                  </div>
                  <div className="w-[161px] relative h-0 z-[-10]">
                    <div className="box-border absolute w-[10px] h-[10px] left-[-18px] top-[-232px] border-[4px] border-[#e6fff7]"></div>
                    <div className="box-border absolute w-[10px] h-[10px] right-[-36px] top-[-232px] border-[4px] border-[#e6fff7]"></div>
                    <div className="box-border absolute w-[10px] h-[10px] left-[-17.5px] bottom-[-2.5px] border-[4px] border-[#e6fff7]"></div>
                    <div className="box-border absolute w-[10px] h-[10px] right-[-36px] top-[-7px] border-[4px] border-[#e6fff7]"></div>
                  </div>
                </div>
              )}
            </li> */}
            {userDetails?.email && (
              <li className="w-[138px] relative h-[40px] flex flex-col items-start">
                <button
                  onClick={handleConnectWallet}
                  style={{
                    boxShadow: '0px 0px 8px rgba(255, 255, 255, 0.4)',
                  }}
                  className="flex cursor-pointer flex-row justify-center items-center p-0 w-[138px] h-[37px] bg-[#0e1815] border border-[#e6fff7] btn"
                >
                  <ul className="flex group relative flex-col items-start py-[11px] px-[7.5px] w-[138px] h-[40px]">
                    <li
                      style={{
                        textShadow: '0px 0px 2px rgba(1, 255, 176, 0.3)',
                      }}
                      className="text-[12px] w-[126px] h-[21px] text-center tracking-[1px] text-[#e6fff7] font-secondary font-[400] leading-[150%] uppercase"
                    >
                      {!wallet
                        ? 'Connect Wallet'
                        : `$ 
                    ${balance}`}

                      <div className="w-[96px] relative h-0 z-[-10]">
                        <div className="box-border absolute w-[10px] h-[10px] left-[-11px] top-[-30px] border-[4px] border-[#e6fff7]"></div>
                        <div className="box-border absolute w-[10px] h-[10px] right-[-38px] top-[-30px] border-[4px] border-[#e6fff7]"></div>
                        <div className="box-border absolute w-[10px] h-[10px] left-[-11px] bottom-[-12.5px] border-[4px] border-[#e6fff7]"></div>
                        <div className="box-border absolute w-[10px] h-[10px] right-[-38px] top-[2.5px] border-[4px] border-[#e6fff7]"></div>
                      </div>
                      {wallet && (
                        <div className="hidden group-hover:block ">
                          <div className="pt-6 -ml-6 bg-transparent">
                            <ul className="top-0 right-[100%] w-48 bg-white rounded-lg divide-y-2 shadow-xl pb-1 px-0">
                              <li className="">
                                <div className="top-0 w-48 relative border rounded-lg border-[#e6fff7]">
                                  <img src={avatar} alt="" className="rounded-md bg-contain" />
                                </div>
                              </li>
                              <li className="relative peer">
                                <a className="block px-4 truncate py-2 text-sm text-black-variant font-semibold font-main hover:bg-gray-500 hover:text-white">
                                  {' '}
                                  {wallet ? wallet.selectedAccount.meta.name : ''}
                                </a>
                              </li>

                              <li
                                className="top-8"
                                onClick={e => {
                                  e.stopPropagation();
                                  handleLogout();
                                }}
                              >
                                <a className="block px-4 truncate py-2 text-sm text-black-variant font-semibold font-main hover:bg-gray-500 hover:text-white">
                                  Logout
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      )}
                    </li>
                  </ul>
                </button>
              </li>
            )}
          </ul>
        </div>
        <div className="flex lg:hidden items-center">
          <ul className="hidden sm:block mr-8 z-[20]">
            {' '}
            <li className="">
              <button
                onClick={handleConnectWallet}
                style={{
                  boxShadow: '0px 0px 8px rgba(255, 255, 255, 0.4)',
                }}
                className="flex cursor-pointer flex-row justify-center items-center p-0 w-[138px] h-[37px] bg-[#0e1815] border border-[#e6fff7] btn"
              >
                <ul className="flex group relative flex-col items-start py-[11px] px-[7.5px] w-[138px] h-[40px]">
                  <li
                    style={{
                      textShadow: '0px 0px 2px rgba(1, 255, 176, 0.3)',
                    }}
                    className="text-[12px] w-[126px] h-[21px] text-center tracking-[1px] text-[#e6fff7] font-secondary font-[400] leading-[150%] uppercase"
                  >
                    {!wallet
                      ? 'Connect Wallet'
                      : `$ 
                    ${balance}`}

                    <div className="w-[96px] relative h-0 z-[-10]">
                      <div className="box-border absolute w-[10px] h-[10px] left-[-11px] top-[-30px] border-[4px] border-[#e6fff7]"></div>
                      <div className="box-border absolute w-[10px] h-[10px] right-[-38px] top-[-30px] border-[4px] border-[#e6fff7]"></div>
                      <div className="box-border absolute w-[10px] h-[10px] left-[-11px] bottom-[-12.5px] border-[4px] border-[#e6fff7]"></div>
                      <div className="box-border absolute w-[10px] h-[10px] right-[-38px] top-[2.5px] border-[4px] border-[#e6fff7]"></div>
                    </div>
                    {wallet && (
                      <div className="hidden group-hover:block ">
                        <div className="pt-6 -ml-6 bg-transparent">
                          <ul className="top-0 right-[100%] w-48 bg-white rounded-lg divide-y-2 shadow-xl py-1 px-0">
                            <li className="relative peer">
                              <a className="block px-4 truncate py-2 text-sm text-black-variant font-semibold font-main hover:bg-gray-500 hover:text-white">
                                {' '}
                                {wallet ? wallet.selectedAccount.meta.name : ''}
                              </a>
                            </li>

                            <li
                              className="top-8"
                              onClick={e => {
                                e.stopPropagation();
                                handleLogout();
                              }}
                            >
                              <a className="block px-4 truncate py-2 text-sm text-black-variant font-semibold font-main hover:bg-gray-500 hover:text-white">
                                Logout
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    )}
                  </li>
                </ul>
              </button>
            </li>
          </ul>
          <div className="z-[99] text-[#e6fff7]" onClick={() => setIsOpen(!isOpen)}>
            {isOpen ? <FaTimes size={26} className="fixed top-[2rem] right-[2rem]" /> : <FaBars size={26} />}
          </div>
        </div>
        {isOpen && (
          <ul
            onClick={() => setIsOpen(!isOpen)}
            className="fixed top-0 right-0 h-screen z-50 w-full flex flex-col justify-center items-center bg-black-variant uppercase text-[#e6fff7] font-main font-[500] gap-[3rem] lg:hidden"
          >
            <li
              onClick={handleModal}
              className="py-6 text-3xl font-[500] cursor-pointer btn  leading-[100%] text-center tracking-[1px]"
            >
              Claim Tokens
            </li>
            <li className="py-6 text-3xl font-[500] cursor-pointer btn  leading-[100%] text-center tracking-[1px]">
              Marketplace
            </li>
            <li className="py-6 text-3xl font-[500] cursor-pointer btn  leading-[100%] text-center tracking-[1px]">
              Builder
            </li>
            <li
              onClick={handleConnectWallet}
              className="sm:hidden py-6 text-3xl bg-[#0e1815] text-[#e6fff7] p-4 font-secondary font-[400] tracking-[1px]"
            >
              {!wallet
                ? 'Connect Wallet'
                : `$ 
                    ${balance}`}
              <div className="w-auto relative h-0 z-[-10]">
                <div className="box-border absolute w-[15px] h-[15px] left-[-19px] top-[-57px] border-[4px] border-[#e6fff7]"></div>
                <div className="box-border absolute w-[15px] h-[15px] right-[-19px] top-[-57px] border-[4px] border-[#e6fff7]"></div>
                <div className="box-border absolute w-[15px] h-[15px] left-[-19px] bottom-[-25px] border-[4px] border-[#e6fff7]"></div>
                <div className="box-border absolute w-[15px] h-[15px] right-[-19px] top-[10px] border-[4px] border-[#e6fff7]"></div>
              </div>
            </li>
          </ul>
        )}
      </nav>
    </>
  );
};

export default Navbar;
