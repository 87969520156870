import React from 'react';
import { useState } from 'react';
import { FaRegUserCircle, FaEdit, FaBars, FaTimes } from 'react-icons/fa';
import logo from '../../assets/logo.png';

const ErrorNavbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <nav className="max-w-[300px] xs:max-w-[28rem] sm:max-w-[38rem] md:max-w-4xl lg:max-w-7xl xl:max-w-[100rem] mx-auto flex items-center justify-between h-[80px] lg:px-[4rem] pt-3">
        <div>
          <img src={logo} alt="" className="cursor-pointer max-w-[200px] h-[35.61px]" />
        </div>
        <div className="z-[10] px-8 hidden md:block">
          <ul className="hidden lg:flex uppercase text-primary font-main font-[500] items-center justify-center gap-[3rem]">
            <li
              style={{ textShadow: '0px 0px 2px rgba(1, 255, 176, 0.3)' }}
              className="font-[500] cursor-pointer btn text-[18px] leading-[100%] text-center tracking-[1px] hover:text-gray-300 "
            >
              Claim Tokens
            </li>
            <li
              style={{ textShadow: '0px 0px 2px rgba(1, 255, 176, 0.3)' }}
              className="font-[500] cursor-pointer text-[18px] leading-[100%] text-center tracking-[1px] hover:text-gray-300 "
            >
              Marketplace
            </li>
            <li
              style={{ textShadow: '0px 0px 2px rgba(1, 255, 176, 0.3)' }}
              className="font-[500] cursor-pointer text-[18px] leading-[100%] text-center tracking-[1px] hover:text-gray-300 "
            >
              Builder
            </li>
          </ul>
        </div>
        <div className="flex lg:hidden items-center">
          <ul className="hidden sm:block mr-8 z-[20]">
            {' '}
            <li className="">
              <button
                style={{
                  boxShadow: '0px 0px 8px rgba(255, 255, 255, 0.4)',
                }}
                className="flex cursor-pointer flex-row justify-center items-center p-0 w-[138px] h-[37px] bg-[#0e1815] border border-[#e6fff7] btn"
              >
                <ul className="flex group relative flex-col items-start py-[11px] px-[7.5px] w-[138px] h-[40px]">
                  <li
                    style={{
                      textShadow: '0px 0px 2px rgba(1, 255, 176, 0.3)',
                    }}
                    className="text-[12px] w-[126px] h-[21px] text-center tracking-[1px] text-[#e6fff7] font-secondary font-[400] leading-[150%] uppercase"
                  >
                    Connect Wallet
                    <div className="w-[96px] relative h-0 z-[-10]">
                      <div className="box-border absolute w-[10px] h-[10px] left-[-11px] top-[-30px] border-[4px] border-[#e6fff7]"></div>
                      <div className="box-border absolute w-[10px] h-[10px] right-[-38px] top-[-30px] border-[4px] border-[#e6fff7]"></div>
                      <div className="box-border absolute w-[10px] h-[10px] left-[-11px] bottom-[-12.5px] border-[4px] border-[#e6fff7]"></div>
                      <div className="box-border absolute w-[10px] h-[10px] right-[-38px] top-[2.5px] border-[4px] border-[#e6fff7]"></div>
                    </div>
                    {/* {wallet && (
                       <div className="hidden group-hover:block ">
                         <div className="pt-6 -ml-6 bg-transparent">
                           <ul className="top-0 right-[100%] w-48 bg-white rounded-lg divide-y-2 shadow-xl py-1 px-0">
                             <li className="relative peer">
                               <a className="block px-4 truncate py-2 text-sm text-black-variant font-semibold font-main hover:bg-gray-500 hover:text-white">
                                 {' '}
                                 {wallet ? wallet.selectedAccount.meta.name : ''}
                               </a>
                             </li>

                             <li
                               className="top-8"
                               
                             >
                               <a className="block px-4 truncate py-2 text-sm text-black-variant font-semibold font-main hover:bg-gray-500 hover:text-white">
                                 Logout
                               </a>
                             </li>
                           </ul>
                         </div>
                       </div>
                     )} */}
                  </li>
                </ul>
              </button>
            </li>
          </ul>
          <div className="z-[99] text-[#e6fff7]" onClick={() => setIsOpen(!isOpen)}>
            {isOpen ? <FaTimes size={26} className="fixed top-[2rem] right-[2rem]" /> : <FaBars size={26} />}
          </div>
        </div>
        {isOpen && (
          <ul
            onClick={() => setIsOpen(!isOpen)}
            className="fixed top-0 right-0 h-screen z-50 w-full flex flex-col justify-center items-center bg-black-variant uppercase text-[#e6fff7] font-main font-[500] gap-[3rem] lg:hidden"
          >
            <li className="py-6 text-3xl font-[500] cursor-pointer btn  leading-[100%] text-center tracking-[1px]">
              Claim Tokens
            </li>
            <li className="py-6 text-3xl font-[500] cursor-pointer btn  leading-[100%] text-center tracking-[1px]">
              Marketplace
            </li>
            <li className="py-6 text-3xl font-[500] cursor-pointer btn  leading-[100%] text-center tracking-[1px]">
              Builder
            </li>
          </ul>
        )}
      </nav>
    </>
  );
};

export default ErrorNavbar;
