import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { IoArrowBackOutline } from 'react-icons/io5';
import Lottie from 'react-lottie';
import { initiateUser } from '../../actions';
import { createUserHandler, getUserDetails, updateUserHandler } from '../../controller/user.controller';
import * as animationData from '../../assets/pinJump.json';
import imageLoad from '../../assets/imageLoader.gif';
import { createWorldHandler, getWorldByUserAndWorld, updateWorldHandler } from '../../controller/world.controller';
import { notify } from '../../App';

export const avatarApiUrl = url => {
  if (!url) return;
  const avatarBaseURL = 'https://api.readyplayer.me/v1/avatars/';
  const avatarParameters = '?scene=fullbody-portrait-v1';
  const filetype = '.png';
  const myArray = url.split('/');
  const newAvatarId = myArray[myArray.length - 1];
  const newArray = newAvatarId.split('.');
  const avatar2dUrl = avatarBaseURL.concat(newArray[0], filetype, avatarParameters);
  return avatar2dUrl;
};

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const Avatar = props => {
  const { initiateUser, userDetails, wallet, api, worldName } = props;
  const [avatarURL, setAvatarURL] = useState('');
  const [userAvatarUrl, setUserAvatarUrl] = useState('');
  const [activatePlay, setActivatePlay] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const subdomain = process.env.REACT_APP_SUBDOMAIN;
    const frame = document.getElementById('frame');

    frame.src = `https://${subdomain}.readyplayer.me/avatar?frameApi`;
  }, []);

  useEffect(() => {
    window.addEventListener('message', subscribe);
    document.addEventListener('message', subscribe);
    function subscribe(event) {
      const json = parse(event);

      if (json?.source !== 'readyplayerme') {
        return;
      }

      if (json.eventName === 'v1.frame.ready') {
        frame.contentWindow.postMessage(
          JSON.stringify({
            target: 'readyplayerme',
            type: 'subscribe',
            eventName: 'v1.**',
          }),
          '*',
        );
      }

      // if (json.eventName === 'v1.avatar.exported' && !userDetails.avatarUrl) {
      if (json.eventName === 'v1.avatar.exported') {
        document.getElementById('frame').hidden = true;

        const apiUrl = avatarApiUrl(json.data.url);
        setAvatarURL(apiUrl);
        setUserAvatarUrl(json.data.url);
      }
    }

    function parse(event) {
      try {
        return JSON.parse(event.data);
      } catch (error) {
        return null;
      }
    }
  }, []);

  // const avatarApiUrl = url => {
  //   const avatarBaseURL = process.env.REACT_APP_READYMEURL;
  //   const avatarParameters = '?scene=fullbody-portrait-v1';
  //   const filetype = '.png';
  //   const myArray = url.split('/');
  //   const newAvatarId = myArray[3];
  //   const newArray = newAvatarId.split('.');
  //   const avatar2dUrl = avatarBaseURL.concat(newArray[0], filetype, avatarParameters);
  //   return avatar2dUrl;
  // };

  // useEffect(() => {
  //   if (userDetails.avatarUrl) {
  //     const avatar2dUrl = avatarApiUrl(userDetails.avatarUrl);
  //     setAvatarURL(avatar2dUrl);
  //     setHeaderText('Welcome');
  //   } else {
  //     setHeaderText('Create your Avatar');
  //   }
  // }, [userDetails]);

  useEffect(() => {
    if (!wallet || !userDetails.email) {
      return navigate('/');
    }
  }, [wallet]);

  // const registerUserInBackend = async () => {
  //   const accountId = wallet.selectedAccount ? wallet.selectedAccount.address : wallet.accounts[0];
  //   const name = userName;
  //   console.log(accountId, name, userEmail);
  //   const result = await createUserHandler(accountId, name, userEmail);
  //   console.log(result);
  //   if (result.status) {
  //     const resultWorld = await createWorldHandler(accountId, 'MetaHome', userAvatarUrl);
  //     const resultWorldDefault = await createWorldHandler(accountId, 'Default', userAvatarUrl);
  //     console.log(resultWorld);
  //     const userDetailsResult = await getUserDetails(accountId);
  //     if (userDetailsResult.status) {
  //       const userDetails = userDetailsResult.data;
  //       userDetails.avatarUrl = userAvatarUrl;
  //       initiateUser(userDetails);
  //     } else {
  //       notify('error', 'Something went wrong!');
  //     }
  //   } else {
  //     notify('error', 'Something went wrong!');
  //   }
  // };

  const createAvatar = async () => {
    const accountId = wallet.selectedAccount.address;
    const fetchDefaultResponse = await getWorldByUserAndWorld(accountId, 'Default');
    const fetchWorldResponse = await getWorldByUserAndWorld(accountId, worldName);
    if (!fetchWorldResponse.status) {
      // create world avatar
      const createWorldResponse = await createWorldHandler(accountId, worldName, userAvatarUrl);
      if (createWorldResponse.status) {
        notify('success', `${worldName} Avatar Created`);
      } else {
        notify('error', `${worldName} Avatar Create Error`);
        return;
      }
      if (!fetchDefaultResponse.status) {
        // create default avatar
        const createDefaultResponse = await createWorldHandler(accountId, 'Default', userAvatarUrl);
        if (createDefaultResponse.status) {
          notify('info', 'Default Avatar Created!');
        } else {
          notify('error', 'Default Avatar Creation Error!');
        }
      }
    } else {
      // update world avatar
      const updateWorldResponse = await updateWorldHandler(accountId, worldName, userAvatarUrl);
      if (updateWorldResponse.status) {
        notify('info', `${worldName} Avatar Updated`);
      } else {
        notify('error', `${worldName} Avatar Update Error`);
        return;
      }
    }
    const tempUserDetails = userDetails;
    tempUserDetails.avatarUrl = userAvatarUrl;
    initiateUser(tempUserDetails);
    setActivatePlay(true);
  };

  const handleClick = async () => {
    await createAvatar();
  };

  return (
    <div className="w-full h-screen bg-black-variant">
      <>
        <div className="grid place-items-center pt-[3%]">
          <div>
            <button
              onClick={() => navigate(-1)}
              className="bg-gray-100 flex btn items-center gap-3 justify-between text-black-variant font-mono text-xl px-[22px] py-[8px] rounded-md shadow-md absolute left-[10%] top-[10%]"
            >
              <IoArrowBackOutline />
              Back
            </button>
          </div>
          <h2 className="text-4xl font-secondary mb-[3rem] font-semibold text-white tracking-wider">Create Avatar </h2>
          <iframe
            id="frame"
            className="frame"
            allow="camera *; microphone *; clipboard-write"
            style={{ width: '70vw', height: '70vh' }}
          ></iframe>
        </div>
      </>
      {avatarURL && (
        <>
          <div className="grid text-gray-300 text-2xl place-items-center py-4">
            <h2>{imageLoaded ? 'Here is your avatar!' : 'Loading your avatar'}</h2>
            <img
              src={avatarURL}
              alt=""
              className={`w-80 h-80 rounded-sm shadow-md ${imageLoaded ? 'opacity-100' : 'hidden'}`}
              onLoad={() => setImageLoaded(true)}
            />
            {!imageLoaded && (
              <div className="">
                {/* <img src={imageLoad} className="w-80 h-80" /> */}
                <Lottie options={defaultOptions} height={320} width={320} />
              </div>
            )}
          </div>

          <div className="grid place-items-center gap-y-2 py-4">
            {!activatePlay ? (
              <button
                style={{
                  background: 'linear-gradient(265.43deg, #721EB5 -33.06%, #1E6CB5 134%)',
                }}
                onClick={handleClick}
                className={`focus:outline-none font-main w-[200px] bg-gray-100 hover:bg-gray-300 focus:ring-2 focus:ring-gray-300 font-semibold rounded-lg text-base tracking-wide px-5 py-2.5 mb-2 border-[#e6fff7] text-[#e6fff7] ${!imageLoaded &&
                  'cursor-not-allowed opacity-75'}`}
              >
                {imageLoaded ? 'Save' : 'Loading...'}
              </button>
            ) : (
              <button
                style={{
                  background: 'linear-gradient(265.43deg, #721EB5 -33.06%, #1E6CB5 134%)',
                }}
                onClick={() => navigate('/metahome')}
                className={`focus:outline-none font-main w-[200px]  bg-gray-100 hover:bg-gray-300 focus:ring-2 focus:ring-gray-300 font-semibold rounded-lg text-base tracking-wide px-5 py-2.5 mb-2 border-[#e6fff7] text-[#e6fff7] ${!imageLoaded &&
                  'cursor-not-allowed opacity-75'}`}
              >
                Play now
              </button>
            )}
          </div>
        </>
      )}
    </div>
  );
};

const mapStateToProps = state => {
  return {
    userDetails: state.userReducer,
    wallet: state.walletReducer,
    api: state.apiReducer,
  };
};

export default connect(mapStateToProps, { initiateUser })(Avatar);
