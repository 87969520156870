import React from 'react';
import ErrorNavbar from '../../components/ErrorNavbar';
import background from '../../assets/bg.png';
import ErrorHero from '../../components/ErrorHero';

const Main = () => {
  return (
    <div className="w-[100%] h-screen bg-black-variant">
      <div style={{ background: `url(${background})` }} className="absolute w-[100%] h-[100%] left-0 top-0"></div>
      <ErrorNavbar />
      <ErrorHero />
    </div>
  );
};

export default Main;
