import React, { useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { initiateUser } from '../../actions';
import { notify } from '../../App';
import Form from '../../components/Form';
import { createUserHandler, getUserDetails } from '../../controller/user.controller';
import { createWorldHandler } from '../../controller/world.controller';

const Register = props => {
  const { initiateUser, wallet } = props;
  // const wallet = useSelector(state => state.walletReducer);

  const [userName, setUserName] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [selectedAccount, setSelectedAccount] = useState(wallet.selectedAccount.address);
  const navigate = useNavigate();

  const accounts = wallet.accounts;

  const handleFormClick = async e => {
    e.preventDefault();
    try {
      if (!selectedAccount || userName === '' || userEmail === '') {
        notify('error', 'Please fill in the details');
      } else {
        const registerUser = await createUserHandler(selectedAccount, userName, userEmail);
        const registerDefaultAvatar = await createWorldHandler(
          selectedAccount,
          'Default',
          'https://api.readyplayer.me/v1/avatars/63d0d5311718944c37d6d13d.glb',
        );
        const registerMetaHomeAvatar = await createWorldHandler(
          selectedAccount,
          'MetaHome',
          'https://api.readyplayer.me/v1/avatars/63d0d5311718944c37d6d13d.glb',
        );
        const registerMetaFantasyAvatar = await createWorldHandler(
          selectedAccount,
          'MetaFantasy',
          'https://api.readyplayer.me/v1/avatars/637615ac5764c3e56af9d52e.glb',
        );
        const registerMetaGalleryAvatar = await createWorldHandler(
          selectedAccount,
          'MetaGallery',
          'https://api.readyplayer.me/v1/avatars/637509fb152ef07e24248c44.glb',
        );
        const registerMetaSpaceAvatar = await createWorldHandler(
          selectedAccount,
          'MetaSpace',
          'https://api.readyplayer.me/v1/avatars/63ce26691718944c37d3c475.glb',
        );
        const registerMetaCityAvatar = await createWorldHandler(
          selectedAccount,
          'MetaCity',
          'https://api.readyplayer.me/v1/avatars/63ce26691718944c37d3c475.glb ',
        );
        console.log(registerUser.status);
        if (registerUser.status) {
          notify('success', 'User registered succesfully');
          const response = await getUserDetails(selectedAccount);

          console.log(response);
          if (response.status) {
            const userDetails = response.data;
            userDetails.avatarUrl = 'https://api.readyplayer.me/v1/avatars/63d0d5311718944c37d6d13d.glb';
            console.log('user details =', userDetails, 'initiating the user now');
            initiateUser(userDetails);
            return navigate('/');
          }
        }
      }
    } catch (error) {
      console.log(error);
      notify('error', error);
    }
  };

  return (
    <div className="w-full rounded-md bg-black-variant">
      {/* <div className="py-[2rem]">
        <h1 className="text-2xl text-gray-100 font-main font-[600] tracking-tight sm:text-center sm:text-6xl">
          Register
        </h1>
      </div> */}
      <Form
        msg="Register User"
        field3="Email"
        field2="Name"
        button="Submit"
        field3val={userEmail}
        setField3={setUserEmail}
        field2val={userName}
        setField2={setUserName}
        onClickHandler={handleFormClick}
        selectedAccount={selectedAccount}
        setSelectedAccount={setSelectedAccount}
        accounts={accounts}
      />
    </div>
  );
};

const mapStateToProps = state => {
  return { userDetails: state.userReducer, wallet: state.walletReducer };
};

export default connect(mapStateToProps, { initiateUser })(Register);
