import { web3Accounts, web3Enable } from '@polkadot/extension-dapp';
import React from 'react';
import { connect, useSelector } from 'react-redux';
import { connectWallet, initiateUser } from '../actions';
import modalImage from '../assets/modalImage.png';
import { getWorldByUserAndWorld } from '../controller/world.controller';
import { getUserAccountDetails } from '../service/storageReader';
import { convertBalance } from '../service/Transactions/utils';
import { getUserDetails } from '../controller/user.controller';

const ConnectWallet = ({ connectWallet, initiateUser }) => {
  const api = useSelector(state => state.apiReducer);
  const handleConnectWallet = async () => {
    try {
      const injectedExtension = await web3Enable('Socialli');
      if (injectedExtension.length > 0) {
        const accounts = await web3Accounts();
        const selectedAccount = await getSelectedAccount(accounts);
        console.log(selectedAccount);
        const wallet = {
          isConnected: true,
          accounts,
          selectedAccount,
        };
        const tokenDetails = await getUserBalanceFromBlockchain(selectedAccount.address);
        connectWallet(wallet);
        initiateUser(tokenDetails);

        // console.log('wallet = ', wallet, ' user = ', userDetails);
      } else {
        alert('no wallet extension found!');
      }
    } catch (error) {
      console.error(error);
    }
  };
  const getSelectedAccount = async accounts => {
    const firstAccount = accounts[0];
    let selectedAccount = null;
    for (let i = 0; i < accounts.length; i++) {
      const account = accounts[i];
      const result = await getUserDetails(account.address);
      if (result.status) {
        const thisUserDetails = result.data;
        const defaultAvatar = await getWorldByUserAndWorld(account.address, 'Default');
        const worldAvatar = await getWorldByUserAndWorld(account.address, 'MetaHome');
        selectedAccount = account;
        if (defaultAvatar.status || worldAvatar.status) {
          thisUserDetails.avatarUrl = worldAvatar.status ? worldAvatar.data.avatarUrl : defaultAvatar.data.avatarUrl;
        }
        console.log('thisUserDetails-- ', thisUserDetails);
        initiateUser(thisUserDetails);
        break;
      }
    }
    return selectedAccount ? selectedAccount : firstAccount;
  };

  const getUserBalanceFromBlockchain = async accountId => {
    const userAccountDetails = await getUserAccountDetails(api, accountId);
    const balanceOfUser = convertBalance(userAccountDetails.data.free);
    const tokenDetails = {
      balance: balanceOfUser,
    };
    return tokenDetails;
  };
  return (
    <div className="flex flex-col items-center justify-center">
      <h1 className="text-2xl font-secondary font-[400] text-gray-700 text-center">Connect your wallet</h1>
      <button
        type="button"
        style={{
          background: 'linear-gradient(265.43deg, #721EB5 -33.06%, #1E6CB5 134%)',
        }}
        onClick={handleConnectWallet}
        className="text-white font-[400] tracking-wide bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300  rounded-md text-md px-5 py-2.5 mr-2 mb-2 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700"
      >
        Connect Wallet
      </button>
    </div>
  );
};

const mapStateToProps = state => {
  return { walletDetails: state.walletReducer, userDetails: state.userReducer };
};

export default connect(mapStateToProps, {
  connectWallet,
  initiateUser,
})(ConnectWallet);
