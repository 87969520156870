import { web3FromAddress } from '@polkadot/extension-dapp';
import { notify } from '../../App.js';

export const transactionEventHandler = (events, status) => {
  const toastArr = [];

  if (status.isInBlock) {
    // notify('info', `Included at block hash ${status.asInBlock.toHex()}`);
    events.forEach(({ event: { data, method, section }, phase }) => {
      if (method === 'ExtrinsicFailed') {
        // notify('error', `Error: \t\t${data.toString()}`);
      } else if (method === 'ExtrinsicSuccess') {
        console.log(`data: ${data}`);
        console.log(`method: ${method}`);
        console.log(`section: ${section}`);
      }
      toastArr.push(`\n${section}.${method}`);
    });
  } else if (status.isFinalized) {
    console.log('Finalized block hash', status.asFinalized.toHex());
  }

  if (toastArr.length > 0) {
    notify('success', `${toastArr}`);
  }
};

export const transactionErrorHandler = err => {
  console.log(`Transaction Error: ${err}`);
};

export const handleSignedTransaction = async (transaction, accountId) => {
  try {
    console.log('trying to transact transaction = ', transaction, ' accountid = ', accountId);
    const injectedAccount = await web3FromAddress(accountId);
    const signer = injectedAccount.signer;
    await transaction.signAndSend(accountId, { signer }, ({ events = [], status }) => {
      transactionEventHandler(events, status);
    });
  } catch (error) {
    transactionErrorHandler(error);
  }
};

export const handleSignedTransactionFromAlice = async (transaction, alice) => {
  try {
    notify('info', 'Airdropping 500 Socialli Tokens');
    await transaction.signAndSend(alice, ({ events = [], status }) => {
      transactionEventHandler(events, status);
    });
  } catch (error) {
    transactionErrorHandler(error);
  }
};
