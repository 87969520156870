import { handleSignedTransaction, transactionErrorHandler } from './handler';

/**
 *
 * @param {*} api
 * @param {string} accountId
 * @param {number} collectionId
 * @param {number} nftId
 * @param {number} amount
 * @returns
 */
export const buyNft = async (api, accountId, collectionId, nftId, amount) => {
  try {
    if (api === null) return;
    const amountInPico = amount * 1000000000;
    const transaction = api.tx.marketplace.buy(collectionId, nftId, amountInPico);
    await handleSignedTransaction(transaction, accountId);
  } catch (error) {
    transactionErrorHandler(error);
  }
};

/**
 *
 * @param {*} api
 * @param {string} accountId
 * @param {number} collectionId
 * @param {number} nftId
 * @param {number} amount
 */
export const listNft = async (api, accountId, collectionId, nftId, amount) => {
		try{
				if(api === null) return;
				const amountInPico = amount * 1000000000;
				const transaction = api.tx.marketplace.list(collectionId, nftId, amountInPico, null);
				await handleSignedTransaction(transaction, accountId);
		}catch(error){
				transactionErrorHandler(error);
		}
};
