import React, { useEffect, useState } from 'react';
import { Unity, useUnityContext } from 'react-unity-webgl';
import { connect } from 'react-redux';
import { Loader } from 'semantic-ui-react';
import Lottie from 'react-lottie';
import { useNavigate } from 'react-router-dom';
import { web3Enable, web3Accounts } from '@polkadot/extension-dapp';

import {
  retriveNfts,
  getUserData,
  buyNftEvent,
  userOwnedNftEvent,
  setCurrentNftEvent,
  sellNftEvent,
  getAllAvatars,
  setActiveAvatar,
} from './event.handler';
import loader from '../../assets/loader.gif';
import * as animationData from '../../assets/Metahome.json';
import { initiateUser, initiateApi, connectWallet } from '../../actions';
import { getWorldByUserAndWorld } from '../../controller/world.controller';
import { useSubstrateState } from '../../substrate-lib';
import { getUserDetails } from '../../controller/user.controller';
import { getUserAccountDetails } from '../../service/storageReader';
import { convertBalance } from '../../service/Transactions/utils';

const MetaHome = props => {
  const dataUrl = `https://d3l7za2sq4osw.cloudfront.net/ReactUnity/MetaHome/MetaHome.data`;
  const loaderUrl = `https://d3l7za2sq4osw.cloudfront.net/ReactUnity/MetaHome/MetaHome.loader.js`;
  const frameworkUrl = `https://d3l7za2sq4osw.cloudfront.net/ReactUnity/MetaHome/MetaHome.framework.js`;
  const codeUrl = `https://d3l7za2sq4osw.cloudfront.net/ReactUnity/MetaHome/MetaHome.wasm`;

  const { userDetails, wallet, api, initiateUser, initiateApi, connectWallet } = props;
  const [userAccountId, setUserAccountId] = useState('');
  const navigate = useNavigate();
  const [avatarUrl, setAvatarUrl] = useState('');
  const substrateState = useSubstrateState();

  // here I am adding the wallet connect functionality for the user to go to metagallery directly
  const handleConnectWallet = async () => {
    console.log('inside handle wallet connect');
    try {
      const injectedExtension = await web3Enable('Socialli');
      if (injectedExtension.length > 0) {
        const accounts = await web3Accounts();
        const selectedAccount = await getSelectedAccount(accounts);
        console.log('got selected account = ', selectedAccount);
        // console.log(selectedAccount);
        const wallet = {
          isConnected: true,
          accounts,
          selectedAccount,
        };
        const tokenDetails = await getUserBalanceFromBlockchain(selectedAccount.address);
        console.log('token details = ', tokenDetails);
        connectWallet(wallet);
        initiateUser(tokenDetails);

        console.log('initiated wallet and user');

        if (!userDetails) {
          notify('success', 'Wallet connected');
        }

        console.log('wallet = ', wallet, ' user = ', userDetails);
      } else {
        alert('no wallet extension found!');
      }
    } catch (error) {
      console.error(error);
    }
  };
  const getSelectedAccount = async accounts => {
    const firstAccount = accounts[0];
    let selectedAccount = null;
    for (let i = 0; i < accounts.length; i++) {
      const account = accounts[i];
      const result = await getUserDetails(account.address);
      if (result.status) {
        const thisUserDetails = result.data;
        const defaultAvatar = await getWorldByUserAndWorld(account.address, 'Default');
        const worldAvatar = await getWorldByUserAndWorld(account.address, 'MetaHome');
        console.log('got account and avatar details');
        selectedAccount = account;
        if (defaultAvatar.status || worldAvatar.status) {
          thisUserDetails.avatarUrl = worldAvatar.status ? worldAvatar.data.avatarUrl : defaultAvatar.data.avatarUrl;
        }
        console.log('initiating user = ', thisUserDetails);
        initiateUser(thisUserDetails);
        break;
      }
    }
    return selectedAccount ? selectedAccount : firstAccount;
  };

  const getUserBalanceFromBlockchain = async accountId => {
    const userAccountDetails = await getUserAccountDetails(api, accountId);
    console.log('user account details received = ', userAccountDetails);
    const balanceOfUser = convertBalance(userAccountDetails.data.free);
    const tokenDetails = {
      balance: balanceOfUser,
    };
    return tokenDetails;
  };

  // console.log(api.query);

  const {
    unityProvider,
    isLoaded,
    sendMessage,
    addEventListener,
    removeEventListener,
    loadingProgression,
  } = useUnityContext({
    dataUrl,
    loaderUrl,
    frameworkUrl,
    codeUrl,
  });

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  useEffect(() => {
    console.log('Inside use effect');
    // console.log(!wallet, !userDetails, !userDetails.avatarUrl);
    if (!wallet && !userDetails && !userDetails?.avatarUrl) {
      // return navigate('/');
      if (!api) {
        const tempApi = substrateState.api;
        if (tempApi.query) {
          console.log('temp api has query inside the useEffect');
          initiateApi(tempApi);
        }
      } else {
        handleConnectWallet();
      }
    } else if (userAccountId === '') {
      if (wallet && userDetails) {
        const account = wallet.selectedAccount ? wallet.selectedAccount.address : wallet.accounts[0].address;
        setUserAccountId(account);
      }
    } else {
      fetchWorldData();
    }

    async function fetchWorldData() {
      const response = await getWorldByUserAndWorld(userAccountId, 'MetaHome');
      console.log(response);
      if (response.status) {
        setAvatarUrl(response.data.avatarUrl);
      } else {
        const response = await getWorldByUserAndWorld(userAccountId, 'Default');
        if (response.status) {
          setAvatarUrl(response.data.avatarUrl);
        } else {
          setAvatarUrl('');
        }
      }
    }
  }, [userDetails, userAccountId]);

  useEffect(() => {
    if (isLoaded) {
      getUserData(addEventListener, sendMessage, userDetails);
      retriveNfts(addEventListener, sendMessage, api);
      buyNftEvent(addEventListener, sendMessage, userDetails, initiateUser, api, userAccountId);
      userOwnedNftEvent(addEventListener, sendMessage, userAccountId, api);
      setCurrentNftEvent(addEventListener, sendMessage, userAccountId);
      sellNftEvent(addEventListener, api, userAccountId);
      getAllAvatars(addEventListener, sendMessage, userAccountId);
      setActiveAvatar(addEventListener, sendMessage, userAccountId);
    }
  }, [isLoaded]);

  const loadingPercentage = Math.round(loadingProgression * 100);

  useEffect(() => {
    console.log('api has changed! - rerendering', api);
    if (!wallet) {
      handleConnectWallet();
    }
  }, [api]);

  useEffect(() => {
    const updateAvatarAnyways = async () => {
      if (wallet) {
        const worldAvatar = await getWorldByUserAndWorld(wallet.selectedAccount.address, 'MetaHome');
        if (worldAvatar.status) {
          const tempUserDetails = userDetails;
          userDetails.avatarUrl = worldAvatar.data.avatarUrl;
          initiateUser(tempUserDetails);
        }
      }
    };
    updateAvatarAnyways();
  }, []);

  if (!api?.query) {
    console.log('not api is happening!');
    const substrateState = useSubstrateState();
    console.log(substrateState);
    const tempApi = substrateState.api;
    if (tempApi.query) {
      console.log('temp api has query');
      initiateApi(tempApi);
    }
    return (
      <div>
        <Loader>Loading.....</Loader>
      </div>
    );
  }

  if (!wallet) {
    return (
      <div>
        <Loader>Loading....</Loader>
      </div>
    );
  }

  if (!wallet || avatarUrl === '') {
    return (
      <div>
        <Loader>Loading....</Loader>
      </div>
    );
  }

  return (
    <div className="bg-black-variant h-full">
      {isLoaded === false && (
        <div className="w-full sticky top-0 flex flex-col items-center pt-[8%] bg-black-variant">
          <Lottie options={defaultOptions} height={400} width={400} />
          <h2 className="text-gray-200 mt-[5%] text-[20px] font-main font-[400] text-center tracking-wide">
            Downloading Assets... ({loadingPercentage}%)
          </h2>
        </div>
      )}

      <Unity unityProvider={unityProvider} className="h-screen w-full" />
    </div>
  );
};

const mapStateToProps = state => {
  return { userDetails: state.userReducer, wallet: state.walletReducer, api: state.apiReducer };
};

export default connect(mapStateToProps, { initiateUser, connectWallet, initiateApi })(MetaHome);
