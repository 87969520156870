import { axiosInstance } from './axios.default';

export const createUserHandler = async (accountId, name, email) => {
  let response = {
    status: false,
  };
  try {
    const result = await axiosInstance.post('/users', {
      name,
      email,
      accountId,
    });
    if (result.status === 200 || result.status === 201) {
      response.status = true;
    }
    return response;
  } catch (error) {
    console.error(error);
    return response;
  }
};

export const deleteUserHandler = async accountId => {
  let response = {
    status: false,
  };
  try {
    const result = await axiosInstance.delete(`/users/deleteUser/${accountId}`);
    if (result.status === 200) {
      response.status = true;
    }
    return response;
  } catch (error) {
    console.error(error);
    return response;
  }
};

export const getUserDetails = async accountId => {
  let response = {
    status: false,
  };

  try {
    const result = await axiosInstance.get(`/users/getUser/${accountId}`);
    if (result.status === 200) {
      response.status = true;
      response.data = result.data;
    }
  } catch (error) {
    console.log(error);
  }
  return response;
};

export const updateUserHandler = async (accountId, updatedUserObject) => {
  let response = {
    status: false,
  };
  try {
    const result = await axiosInstance.put(`/users/updateUser/${accountId}`, {
      ...updatedUserObject,
    });
    if (result.status === 200) {
      response.status = true;
    }
    return response;
  } catch (error) {
    console.error(error);
    return response;
  }
};
