/* This example requires Tailwind CSS v2.0+ */
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Modal } from 'semantic-ui-react';
import ConfirmAvatar from './ConfirmAvatar';
import ConnectWallet from './ConnectWallet';
import ClaimToken from './ClaimToken';
import Register from '../Views/Register';

export default function MultiFormModal({ showMultiFormModal, setShowMultiFormModal }) {
  const [step, setStep] = useState(1);
  const wallet = useSelector(state => state.walletReducer);
  const userDetails = useSelector(state => state.userReducer);
  const api = useSelector(state => state.apiReducer);
  const navigate = useNavigate();

  useEffect(() => {
    if (wallet?.isConnected) {
      setStep(2);
    }
    if (userDetails?.email) {
      setStep(3);
    }
    if (userDetails?.avatarUrl) {
      //   return navigate('/metahome');
    }
  }, [wallet, userDetails]);

  let size;

  if (step === 1) {
    size = 'tiny';
  } else if (step === 2) {
    size = 'small';
  } else if (step === 3) {
    size = 'tiny';
  } else {
    size = 'small';
  }

  return (
    <Modal
      open={showMultiFormModal}
      onClose={() => setShowMultiFormModal(false)}
      onOpen={() => setShowMultiFormModal(true)}
      size={size}
    >
      <Modal.Header className="text-center">Let's get you started</Modal.Header>
      <Modal.Content scrolling>
        {step === 1 && <ConnectWallet />}
        {step === 2 && (
          <div>
            <Register />
          </div>
        )}
        {step === 3 && <ClaimToken api={api} wallet={wallet} setStep={setStep} />}
        {step === 4 && <ConfirmAvatar avatarUrl={userDetails.avatarUrl} />}
      </Modal.Content>
    </Modal>
  );
}
