import React from 'react';
import { Dimmer, Loader, Grid, Message } from 'semantic-ui-react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Lottie from 'react-lottie';
import { SubstrateContextProvider, useSubstrateState } from './substrate-lib';
import Home from './Views/Home';
import Avatar from './Views/Avatar';
import Metahome from './Views/Metahome';
import Register from './Views/Register';
import ErrorPage from './Views/ErrorPage';
import * as animationData from '../src/assets/Error.json';

export const toastOptions = {
  position: 'bottom-right',
  autoClose: 3000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  theme: 'dark',
};

export const notify = (type, message) => {
  if (type === 'success') {
    toast.success(message, toastOptions);
  }
  if (type === 'error') {
    toast.error(message, toastOptions);
  }
  if (type === 'info') {
    toast.info(message, toastOptions);
  }
};

function Main() {
  const { apiState, apiError, keyringState } = useSubstrateState();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const notifyWithToast = msg => {
    toast(msg);
  };

  const loader = text => (
    <Dimmer active>
      <Loader size="small">{text}</Loader>
    </Dimmer>
  );

  const message = errObj => <ErrorPage />;

  if (apiState === 'ERROR') return message(apiError);
  else if (apiState !== 'READY') return loader('Connecting to Substrate');

  if (keyringState !== 'READY') {
    return loader("Loading accounts (please review any extension's authorization)");
  }

  const router = createBrowserRouter([
    {
      path: '/',
      element: <Home notifyWithToast={notifyWithToast} />,
      errorElement: <div className="grid place-items-center">Page not found!!</div>,
    },
    {
      path: 'avatar',
      element: <Avatar notifyWithToast={notifyWithToast} worldName={'MetaHome'} />,
    },
    {
      path: 'metahome',
      element: <Metahome />,
    },
    {
      path: 'register',
      element: <Register />,
    },
  ]);

  return (
    <div>
      <RouterProvider router={router} />
      <ToastContainer />
    </div>
  );
}

export default function App() {
  return (
    <SubstrateContextProvider>
      <Main />
    </SubstrateContextProvider>
  );
}
