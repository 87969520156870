import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { web3Enable, web3Accounts } from '@polkadot/extension-dapp';
import { useNavigate } from 'react-router-dom';
import { useSubstrateState } from '../../substrate-lib';
import { getUserAccountDetails } from '../../service/storageReader';
import Main from '../../components/Main/Main';
import About from '../../components/About/About';
import Services from '../../components/Services/Services';
import Footer from '../../components/Footer/Footer';
import 'react-toastify/dist/ReactToastify.css';
import { convertBalance } from '../../service/Transactions/utils';
import { notify } from '../../App';
import { getUserDetails } from '../../controller/user.controller';
import { airdrop } from '../../service/Transactions/metahomeDexExtrinsics';
import { connectWallet, initiateApi, initiateUser } from '../../actions';
import { getWorldByUserAndWorld } from '../../controller/world.controller';
import MultiFormModal from '../../components/MultiFormModal';
import Modal from '../../components/Modal';

const Home = props => {
  const { walletDetails, userDetails, connectWallet, initiateApi, initiateUser } = props;
  const [balance, setBalance] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [showMultiFormModal, setShowMultiFormModal] = useState(false);

  const { api } = useSubstrateState();

  const navigate = useNavigate();

  useEffect(() => {
    if (userDetails && userDetails.balance) {
      const displayBalance = userDetails.balance / 1000;
      setBalance(displayBalance);
    }
  }, [userDetails, walletDetails]);

  useEffect(() => {
    // if (walletDetails && !userDetails.email) {
    //   handleConnectWallet();
    // }
    // if (walletDetails && !userDetails.avatarUrl) {
    //   handleConnectWallet();
    // }
    if (walletDetails && walletDetails.selectedAccount) {
      const initUserBalance = async () => {
        const tokenDetails = await getUserBalanceFromBlockchain(walletDetails.selectedAccount.address);
        initiateUser(tokenDetails);
      };
      initUserBalance();
    }
  }, []);

  const handleExploreNowClick = () => {
    if (walletDetails && userDetails && userDetails.avatarUrl) {
      return navigate('/metahome');
    } else if (walletDetails && userDetails.email) {
      return navigate('/avatar');
    } else if (walletDetails) {
      return navigate('/register');
    } else {
      setShowMultiFormModal(true);
      // notify('error', 'Please connect your wallet first!');
    }
  };

  const handleLogout = () => {
    initiateUser(null);
    connectWallet(null);
    notify('success', 'Wallet disconnected');
  };

  const handleAirdrop = async showModal => {
    if (!walletDetails) {
      notify('error', 'Please connect your wallet!');
      return;
    }
    await airdrop(api, walletDetails.selectedAccount.address);
    setTimeout(async () => {
      const tokenDetails = await getUserBalanceFromBlockchain(walletDetails.selectedAccount.address);
      initiateUser(tokenDetails);
      setShowModal(!showModal);
    }, 6000);
  };

  const handleConnectWallet = async () => {
    try {
      const injectedExtension = await web3Enable('Socialli');
      if (injectedExtension.length > 0) {
        const accounts = await web3Accounts();
        const selectedAccount = await getSelectedAccount(accounts);
        console.log(selectedAccount);
        const wallet = {
          isConnected: true,
          accounts,
          selectedAccount,
        };
        const tokenDetails = await getUserBalanceFromBlockchain(selectedAccount.address);
        connectWallet(wallet);
        initiateUser(tokenDetails);

        if (!userDetails) {
          notify('success', 'Wallet connected');
        }

        console.log('wallet = ', wallet, ' user = ', userDetails);
      } else {
        alert('no wallet extension found!');
      }
    } catch (error) {
      console.error(error);
    }
  };
  const getSelectedAccount = async accounts => {
    const firstAccount = accounts[0];
    let selectedAccount = null;
    for (let i = 0; i < accounts.length; i++) {
      const account = accounts[i];
      const result = await getUserDetails(account.address);
      if (result.status) {
        const thisUserDetails = result.data;
        const defaultAvatar = await getWorldByUserAndWorld(account.address, 'Default');
        const worldAvatar = await getWorldByUserAndWorld(account.address, 'MetaHome');
        selectedAccount = account;
        if (defaultAvatar.status || worldAvatar.status) {
          thisUserDetails.avatarUrl = worldAvatar.status ? worldAvatar.data.avatarUrl : defaultAvatar.data.avatarUrl;
        }
        initiateUser(thisUserDetails);
        break;
      }
    }
    return selectedAccount ? selectedAccount : firstAccount;
  };

  const getUserDetailsFromBackend = async accountId => {
    const result = await getUserDetails(accountId);
    return result;
  };

  const getUserBalanceFromBlockchain = async accountId => {
    const userAccountDetails = await getUserAccountDetails(api, accountId);
    const balanceOfUser = convertBalance(userAccountDetails.data.free);
    const tokenDetails = {
      balance: balanceOfUser,
    };
    return tokenDetails;
  };

  const handleModal = () => {
    handleAirdrop(showModal);
    // setShowModal(!showModal);
  };

  initiateApi(api);

  return (
    <>
      <Main
        balance={balance}
        handleExploreNowClick={handleExploreNowClick}
        handleConnectWallet={handleConnectWallet}
        handleLogout={handleLogout}
        handleAirdrop={handleAirdrop}
        handleModal={handleModal}
        setShowModal={setShowModal}
      />
      {showModal && <Modal setShowModal={setShowModal} />}
      {showMultiFormModal && (
        <div className="">
          <MultiFormModal
            handleConnectWallet={handleConnectWallet}
            showMultiFormModal={showMultiFormModal}
            setShowMultiFormModal={setShowMultiFormModal}
          />
        </div>
      )}
      <About />
      <Services />
      <Footer />
    </>
  );
};

const mapStateToProps = state => {
  return { walletDetails: state.walletReducer, userDetails: state.userReducer };
};

export default connect(mapStateToProps, {
  connectWallet,
  initiateApi,
  initiateUser,
})(Home);
