import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Dropdown } from 'semantic-ui-react';

const Form = props => {
  const options = props.accounts.map((acc, index) => ({
    value: acc.address,
    text: acc.address,
    key: index,
  }));

  const handleOnChange = (e, { value }) => {
    props.setSelectedAccount(value);
    console.log(props.selectedAccount);
  };

  return (
    <div className="flex items-center justify-center py-10">
      <div className="w-full max-w-md space-y-8">
        <div>
          <h2 className="mt-6 font-secondary tracking-wider text-white text-center text-2xl">{props.msg}</h2>
        </div>
        <form className="mt-8 space-y-6" action="#" method="POST">
          <input type="hidden" name="remember" defaultValue="true" />
          <div className="space-y-5 rounded-md shadow-sm">
            <div>
              <Dropdown
                selection
                options={options}
                placeholder="Select an account"
                onChange={handleOnChange}
                value={props.selectedAccount}
                className="block w-full text-sm"
              />
            </div>

            <div>
              <label htmlFor="name" className="sr-only">
                {props.field2}
              </label>
              <input
                name={props.field2}
                value={props.field2val}
                onChange={e => props.setField2(e.target.value)}
                required
                className="relative block font-main w-full appearance-none bg-white rounded-md border border-gray-300 px-3 py-2 text-black-variant placeholder-gray-500 focus:z-10 focus:border-gray-500 focus:outline-none focus:ring-gray-500 sm:text-sm"
                placeholder={props.field2}
              />
            </div>
            <div>
              <label htmlFor="Email" className="sr-only">
                {props.field3}
              </label>
              <input
                name={props.field3}
                value={props.field3val}
                onChange={e => props.setField3(e.target.value)}
                required
                className="relative block font-main w-full bg-white appearance-none rounded-md border border-gray-100 px-3 py-2 text-black-variant placeholder-gray-500 focus:z-10 focus:border-gray-500 focus:outline-none focus:ring-gray-500 sm:text-sm"
                placeholder={props.field3}
              />
            </div>
          </div>

          <div>
            <button
              onClick={props.onClickHandler}
              type="submit"
              style={{
                background: 'linear-gradient(265.43deg, #721EB5 -33.06%, #1E6CB5 134%)',
              }}
              className="group font-main relative flex w-full justify-center border-1 border-[#e6fff7] text-[#e6fff7] rounded-md  border-transparent bg-gray-100 py-2 px-4 text-lg font-medium hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-700 focus:ring-offset-2"
            >
              {props.button}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Form;
