import React from 'react';
import Main from './Main';
import About from '../../components/About/About';
import Services from '../../components/Services/Services';
import Footer from '../../components/Footer/Footer';

const ErrorPage = () => {
  return (
    <>
      <Main />
      <About />
      <Services />
      <Footer />
    </>
  );
};

export default ErrorPage;
