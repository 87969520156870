import React from 'react';
import servicesImage from '../../assets/servicesImage.png';
import background from '../../assets/bg.png';

const Services = () => {
  return (
    <div className="relative w-full h-[750px] pt-[50px] bg-black-variant">
      <div
        style={{
          background: `url(${background})`,
          background: 'linear-gradient(180deg, #000000 0%, #26006F 35.82%, #000000 100%)',
        }}
        className="absolute w-full h-[750px] left-0 top-0"
      ></div>
      <div className="flex flex-col items-center">
        <img src={servicesImage} alt="" className="absolute h-[500px] xs:w-[1163px] xs:h-[613px]" />
      </div>
      <div
        style={{ filter: 'blur(150px)' }}
        className="absolute xs:w-[325px] xs:h-[325px] left-[25%] top-[25%] lg:left-[567px] lg:top-[157px] bg-[#0094ff] opacity-50"
      ></div>
    </div>
  );
};

export default Services;
