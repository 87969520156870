import React from 'react';
import sampleImage from '../../assets/sample.png';

const Hero = ({ handleExploreNowClick }) => {
  return (
    <div className="max-w-[300px] xs:max-w-sm sm:max-w-[38rem] md:max-w-4xl lg:max-w-5xl xl:max-w-[92rem] mx-auto min-h-[calc(100vh-80px)] grid sm:grid-cols-2 items-center justify-center">
      <div className="flex flex-col z-10 xs:gap-[70px] md:gap-[48px] sm:w-[550px] md:w-[687px] h-[304px]">
        <div className="">
          <h2
            style={{ textShadow: '0px 0px 2px rgba(1, 255, 176, 0.3)' }}
            className="xs:w-[441px] xs:h-[144px] font-main font-semibold text-[40px] xs:text-[48px] xs:leading-[150%] text-[#E6FFF7] "
          >
            Own a home in the Metaverse
          </h2>
          <p className="xs:h-[36px] font-main font-[400] text-[18px] xs:text-[24px] xs:leading-[150%] text-secondary">
            Host events, Flaunt NFT’s and Invite friends to your home{' '}
          </p>
        </div>
        <div className="space-y-16 xs:space-y-6 flex flex-col-reverse gap-[1rem] xs:block">
          <button
            style={{
              textShadow: '0px 0px 2px rgba(1, 255, 176, 0.3)',
            }}
            className="gap-[24px] w-[230px] h-[52px] btn"
          >
            <div
              className="w-[230px] h-[52px] border border-[#e6fff7]"
              style={{
                background: 'linear-gradient(265.43deg, #721EB5 -33.06%, #1E6CB5 134%)',
                boxShadow: '0px 0px 8px rgba(255, 255, 255, 0.4)',
              }}
              onClick={handleExploreNowClick}
            >
              <div className="py-[8px] px-[24px] w-[230px] h-[52px]">
                <p className="text-[24px] w-[192px] h-[36px] text-center tracking-[1px] text-[#e6fff7] font-main font-semibold leading-[150%] uppercase">
                  Explore now
                </p>
                <div className="w-[161px] relative h-0 z-[-10]">
                  <div className="box-border absolute w-[10px] h-[10px] left-[-28px] top-[-72px] border-[4px] border-[#e6fff7]"></div>
                  <div className="box-border absolute w-[10px] h-[10px] right-[-47.5px] top-[-72px] border-[4px] border-[#e6fff7]"></div>
                  <div className="box-border absolute w-[10px] h-[10px] left-[-28px] bottom-[13.5px] border-[4px] border-[#e6fff7]"></div>
                  <div className="box-border absolute w-[10px] h-[10px] right-[-47.5px] top-[-23.5px] border-[4px] border-[#e6fff7]"></div>
                </div>
              </div>
            </div>
          </button>
          <button className="gap-[24px] w-[230px] h-[52px]">
            <div
              className="w-[230px] h-[52px] "
              style={{
                filter: 'dropShadow(0px 0px 8px rgba(255, 255, 255, 0.4))',
              }}
            >
              <div className="py-[8px] px-[24px] w-[230px] h-[52px] ">
                <p
                  style={{ textShadow: '0px 0px 2px rgba(1, 255, 176, 0.3)' }}
                  className="text-[24px] w-[182px] h-[36px] text-center tracking-[1px] text-[#e6fff7] font-main font-semibold leading-[150%] uppercase"
                >
                  Watch video
                </p>
              </div>
            </div>
          </button>
        </div>
      </div>
      <div className="hidden md:block md:w-[400px] lg:w-[520px] lg:h-[289px] xl:w-[650px] xl:h-[339px] 2xl:w-[825px] 2xl:h-[539px]">
        <img src={sampleImage} alt="" />
      </div>
    </div>
  );
};

export default Hero;
