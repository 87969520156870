import axios from 'axios';
import { getNftDetails, getListedNft, getLatestCollectionIndex, getLatestNftIndex } from '../../service/storageReader';
import { convertBalance } from '../../service/Transactions/utils';

export const getAllListedNfts = async api => {
  try {
    console.log('this is api.query = ', api.query);
    const listOfNfts = [];
    const maxCollectionId = await getLatestCollectionIndex(api);
    for (let currentCollectionIndex = 0; currentCollectionIndex < maxCollectionId; currentCollectionIndex++) {
      const maxNftId = await getLatestNftIndex(api, currentCollectionIndex);
      for (let currentNftIndex = 0; currentNftIndex < maxNftId; currentNftIndex++) {
        const listedNft = await getListedNft(api, currentCollectionIndex, currentNftIndex);
        if (listedNft) {
          const nftDetails = await getNftDetails(api, currentCollectionIndex, currentNftIndex);
          // console.log(nftDetails);
          const res = await axios.get(nftDetails.metadata);
          console.log('res = ', res);
          // const metadata = JSON.parse(res.data);
          const metadata = res.data;
          if (typeof metadata === typeof '') continue;
          const nft = {
            collectionId: currentCollectionIndex,
            nftId: currentNftIndex,
            owner: nftDetails.owner.AccountId,
            metadata: metadata.image,
            cost: convertBalance(listedNft.amount),
            nftName: metadata.name,
            description: metadata.description,
          };
          listOfNfts.push(nft);
        }
      }
    }
    return { nfts: listOfNfts };
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const getAllNftsOwnedByUser = async (api, accountId) => {
  try {
    const userNftList = [];
    const maxCollectionId = await getLatestCollectionIndex(api);
    for (let currentCollectionIndex = 0; currentCollectionIndex < maxCollectionId; currentCollectionIndex++) {
      const maxNftId = await getLatestNftIndex(api, currentCollectionIndex);
      for (let currentNftIndex = 0; currentNftIndex < maxNftId; currentNftIndex++) {
        const nft = await getNftDetails(api, currentCollectionIndex, currentNftIndex);
        if (nft && nft.owner) {
          if (nft.owner.AccountId === accountId) {
            nft.collectionId = currentCollectionIndex;
            nft.nftId = currentNftIndex;
            const res = await axios.get(nft.metadata);
            // const metadata = JSON.parse(res.data);
            const metadata = res.data;
            if (typeof metadata === typeof '') continue;
            nft.metadata = metadata.image;
            nft.name = metadata.name;
            nft.description = metadata.description;
            userNftList.push(nft);
          }
        }
      }
    }
    console.log('user nfts :');
    return { nfts: userNftList };
  } catch (error) {
    console.error(error);
    return { userNftList: [] };
  }
};
