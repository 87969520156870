import React from 'react';
import modalImage from '../assets/modalImage.png';

const Modal = ({ setShowModal }) => {
  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto my-6 mx-auto max-w-3xl">
          <div
            style={{
              background: 'linear-gradient(265.43deg, #721EB5 -33.06%, #1E6CB5 134%)',
              boxShadow: '0px 0px 8px rgba(255, 255, 255, 0.4)',
            }}
            className="border-0 rounded-lg shadow-lg relative flex flex-col w-full outline-none focus:outline-none"
          >
            <div className="border border-[#e6fff7]">
              <div className="flex">
                <div className="flex items-start justify-between p-5"></div>
                <div className="relative p-6 flex-auto">
                  <h2 className="text-[#e6fff7] font-secondary font-[400]">Congratulations!</h2>
                  <p className="my-4 text-white text-lg font-main leading-relaxed">
                    We just airdropped <span className="font-semibold">500 Social Li</span> tokens as a Welcome Gift!
                    You can win Social Li tokens going forward by performing various in-game actions.
                  </p>
                  <p className="my-4 text-white text-lg font-main leading-relaxed">
                    So the more you play, the more you earn! Isn’t that cool? <br /> Now go ahead, and create your
                    Avatar by clicking on <span className="font-semibold"> “Explore Now”</span>. You can customize your
                    avatar and your attire to reflect your online personality!
                  </p>
                </div>
              </div>
              <div className="flex items-center justify-end p-6 rounded-b">
                <button
                  className="text-white background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear border border-[#e6fff7] transition-all duration-150 hover:bg-white hover:text-black-variant"
                  type="button"
                  onClick={() => setShowModal(false)}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
          <div className="w-auto relative h-0 z-[-10]">
            <div className="box-border absolute w-[20px] h-[20px] left-[-4px] top-[-313px] border-[4px] border-[#e6fff7]"></div>
            <div className="box-border absolute w-[20px] h-[20px] right-[-4px] top-[-313px] border-[4px] border-[#e6fff7]"></div>
            <div className="box-border absolute w-[20px] h-[20px] left-[-4px] bottom-[-4px] border-[4px] border-[#e6fff7]"></div>
            <div className="box-border absolute w-[20px] h-[20px] right-[-4px] top-[-16px] border-[4px] border-[#e6fff7]"></div>
          </div>
        </div>
      </div>
      <div className="opacity-50 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};

export default Modal;
