import { getAllListedNfts, getAllNftsOwnedByUser } from './utils';
import { buyNft, listNft } from '../../service/Transactions/metahomeMarketplaceExtrinsics';
import { getUserAccountDetails } from '../../service/storageReader';
import { convertBalance } from '../../service/Transactions/utils';
import { updateUserHandler } from '../../controller/user.controller.js';
import { getAllWorldsByUser, updateWorld } from '../../controller/world.controller';

export const retriveNfts = (addEventListener, sendMessage, api) => {
  addEventListener('RetrieveNFTs', async function testSend(gameObject, getNftFunction) {
    const nftArray = await getAllListedNfts(api);
    const nftsInJSON = JSON.stringify(nftArray);
    console.log('retrieve nft is happening = ', nftsInJSON);
    sendMessage(gameObject, getNftFunction, nftsInJSON);
  });
};

export const getUserData = (addEventListener, sendMessage, userDetails) => {
  console.log('user details = ', userDetails);
  addEventListener('GetUserData', function(gameObjectName, functionName) {
    console.log(`game object name = ${gameObjectName}, functionName = ${functionName}`);
    console.log('userDetails = ', userDetails);
    const userDetailsJson = JSON.stringify(userDetails);
    console.log(userDetailsJson);
    sendMessage(gameObjectName, functionName, userDetailsJson);
  });
};

export const buyNftEvent = (addEventListener, sendMessage, userDetails, initiateUser, api, userAccountId) => {
  addEventListener('BuyNFT', async function buyTheNft(
    gameObjecName,
    functionName1,
    functionName2,
    collectionId,
    nftId,
    nftCost,
  ) {
    await buyNft(api, userAccountId, collectionId, nftId, nftCost);
    setTimeout(async () => {
      const nftArray = await getAllListedNfts(api);
      const nftsInJSON = JSON.stringify(nftArray);
      const balanceDetails = await getUserAccountDetails(api, userAccountId);
      const balance = convertBalance(balanceDetails.data.free);
      const userAccountDetails = userDetails;
      userAccountDetails.balance = balance;
      console.log(userAccountDetails);
      const userAccountDetailsJson = JSON.stringify(userAccountDetails);
      sendMessage(gameObjecName, functionName1, nftsInJSON);
      sendMessage(gameObjecName, functionName2, userAccountDetailsJson);
      initiateUser(userAccountDetails);
    }, 6000);
  });
};

export const userOwnedNftEvent = (addEventListener, sendMessage, accountId, api) => {
  addEventListener('GetUserOwnedNFT', async function getUserOwnedNftFromBackend(gameObjectName, functionName) {
    const userNftObject = await getAllNftsOwnedByUser(api, accountId);
    const userNftObjectJson = JSON.stringify(userNftObject);
    sendMessage(gameObjectName, functionName, userNftObjectJson);
  });
};

export const setCurrentNftEvent = (addEventListener, sendMessage, accountId) => {
  addEventListener('SetCurrentNFT', async function setCurrentNftForUser(
    gameObjectName,
    functionName,
    collectionId,
    nftId,
  ) {
    const currentNft = collectionId === -1 ? [] : [collectionId, nftId];
    const updatedUserObject = {
      currentNft,
    };
    const result = await updateUserHandler(accountId, updatedUserObject);
    const response = result.status ? 1 : 0;
    sendMessage(gameObjectName, functionName, response);
  });
};

export const sellNftEvent = (addEventListener, api, accountId) => {
  addEventListener('SellNFT', async function listUserNft(collectionId, nftId, cost) {
    await listNft(api, accountId, collectionId, nftId, cost);
  });
};

export const getAllAvatars = (addEventListener, sendMessage, accountId) => {
  addEventListener('GetAllAvatars', async function sendAllAvatars(avatarObj, getAllAvatarFunc) {
    // const response = await getAllWorldsByUser(accountId);
    // const avatars = response.data.map(data => data.avatarUrl);
    // const uniqueAvatars = [...new Set(avatars)];
    // console.log(uniqueAvatars);
    const avatarArray = [
      'https://api.readyplayer.me/v1/avatars/63d0d5311718944c37d6d13d.glb',
      'https://api.readyplayer.me/v1/avatars/637509fb152ef07e24248c44.glb',
      'https://api.readyplayer.me/v1/avatars/637615ac5764c3e56af9d52e.glb',
      'https://api.readyplayer.me/v1/avatars/63ce26691718944c37d3c475.glb',
    ];
    const avatarData = {
      avatarArray: avatarArray,
    };
    const avatarsJSON = JSON.stringify(avatarData);
    sendMessage(avatarObj, getAllAvatarFunc, avatarsJSON);
  });
};

export const setActiveAvatar = (addEventListener, sendMessage, accountId, worldName) => {
  addEventListener('SetActiveAvatar', async function sendAllAvatars(
    activeAvatarObj,
    getActiveAvatarFunc,
    currentAvatarUrl,
    worldName,
  ) {
    console.log('this is the world name = ', worldName, ' this is the avatar url = ', currentAvatarUrl);
    const avatarUrl = currentAvatarUrl.trim();
    const response = await updateWorld(accountId, worldName, avatarUrl);
    let flag = 0;
    if (response.status) flag = 1;
    sendMessage(activeAvatarObj, getActiveAvatarFunc, flag);
  });
};
