import React from 'react';
import Navbar from '../Navbar/Navbar';
import Hero from '../Hero/Hero';
import background from '../../assets/bg.png';

const Main = ({ handleConnectWallet, balance, handleExploreNowClick, handleLogout, handleAirdrop, handleModal }) => {
  return (
    <div className="w-[100%] h-screen bg-black-variant">
      <div style={{ background: `url(${background})` }} className="absolute w-[100%] h-[100%] left-0 top-0"></div>
      <Navbar
        balance={balance}
        handleConnectWallet={handleConnectWallet}
        handleLogout={handleLogout}
        handleAirdrop={handleAirdrop}
        handleModal={handleModal}
      />
      <Hero handleExploreNowClick={handleExploreNowClick} />
    </div>
  );
};

export default Main;
