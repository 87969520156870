import { combineReducers } from 'redux';

import { WALLET, USER, API } from '../types';

const walletReducer = (wallet = null, action) => {
  if (action.type === WALLET) {
    if (action.payload === null) return null;
    else return { ...wallet, ...action.payload };
  }

  return wallet;
};

const userReducer = (user = null, action) => {
  if (action.type === USER) {
    if (action.payload === null) {
      console.log('tring null user');
      return null;
    } else {
      return { ...user, ...action.payload };
    }
  }

  return user;
};

const apiReducer = (api = null, action) => {
  if (action.type === API) {
    return action.payload;
  }

  return api;
};

export default combineReducers({
  walletReducer,
  userReducer,
  apiReducer,
});
