/**
 *
 * @param {*} api
 * @param {string} account
 * @returns
 */
export const getUserDetails = async (api, account) => {
  if (api === null) return;
  const user = await api.query.user.user(account);
  return user.toHuman();
};
/**
 *
 * @param {*} api
 * @param {string} account
 * @returns
 */
export const getUserAccountDetails = async (api, account) => {
  if (api === null) return;
  const accountDetails = await api.query.system.account(account);
  return accountDetails.toHuman();
};

/**
 *
 * @param {*} api
 * @returns
 */
export const getLatestCollectionIndex = async api => {
  if (api === null) return;
  const collectionIndex = await api.query.metahomeNft.collectionIndex();
  return collectionIndex.toHuman();
};

/**
 *
 * @param {*} api
 * @param {number} collectionIndex
 * @returns
 */
export const getLatestNftIndex = async (api, collectionIndex) => {
  if (api === null) return;

  const nftIndex = await api.query.metahomeNft.nextNftId(collectionIndex);
  return nftIndex;
};

/**
 *
 * @param {*} api
 * @param {number} collectionId
 * @param {number} nftId
 * @returns
 */
export const getNftDetails = async (api, collectionId, nftId) => {
  if (api === null) return;

  const nft = await api.query.metahomeNft.nfts(collectionId, nftId);
  return nft.toHuman();
};

/**
 *
 * @param {*} api
 * @param {number} collectionId
 * @param {number} nftId
 * @returns
 */
export const getListedNft = async (api, collectionId, nftId) => {
  try {
    if (api === null) return;

    const nft = await api.query.marketplace.listedNfts(collectionId, nftId);
    return nft.toHuman();
  } catch (error) {
    console.error(error);
    return null;
  }
};
